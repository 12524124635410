import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { auditTime, distinctUntilChanged, map } from 'rxjs/operators';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

@Injectable({
  providedIn: 'root',
})
export class BreakPointService {
  isXs$: Observable<boolean> = this.breakpointObserver
    .observe(['(max-width: 639px)'])
    .pipe(map((result) => result.matches));

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(['(max-width: 1023px)'])
    .pipe(map((result) => result.matches));

  isSm$: Observable<boolean> = combineLatest([
    this.breakpointObserver.observe(['(min-width: 640px)']),
    this.breakpointObserver.observe(['(max-width: 767px)']),
  ]).pipe(map(([min, max]) => min.matches && max.matches));

  isMd$: Observable<boolean> = combineLatest([
    this.breakpointObserver.observe(['(min-width: 768px)']),
    this.breakpointObserver.observe(['(max-width: 1023px)']),
  ]).pipe(map(([min, max]) => min.matches && max.matches));

  isLg$: Observable<boolean> = combineLatest([
    this.breakpointObserver.observe(['(min-width: 1024px)']),
    this.breakpointObserver.observe(['(max-width: 1279px)']),
  ]).pipe(map(([min, max]) => min.matches && max.matches));

  gtLg$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width: 1280px)'])
    .pipe(map((result) => result.matches));

  isFullWidth$: Observable<boolean> = this.breakpointObserver
    .observe(['(min-width: 1535px)'])
    .pipe(map((result) => result.matches));

  breakpoints$: Observable<Breakpoint> = combineLatest([
    this.isXs$.pipe(map((r) => (r ? 'xs' : null))),
    this.isSm$.pipe(map((r) => (r ? 'sm' : null))),
    this.isMd$.pipe(map((r) => (r ? 'md' : null))),
    this.isLg$.pipe(map((r) => (r ? 'lg' : null))),
    this.gtLg$.pipe(map((r) => (r ? 'xl' : null))),
  ]).pipe(
    auditTime(0),
    map((result) => result.find((r) => !!r) as Breakpoint),
    distinctUntilChanged(),
  );

  constructor(private breakpointObserver: BreakpointObserver) {}
}
